<script setup>

const tableData = [
  {
    id:"160845",
    control:"登陆系统",
    address: '183.220.76.28',
    user_name: '广西程库科技有限公司 ',
    user_id: 1,
    time:"2023-1-31 11:38",
    status:0
  },
  {
    id:"160846",
    control:"查看商户资料",
    address: '183.220.76.28',
    user_name: '广西程库科技有限公司 ',
    user_id: 1,
    time:"2023-1-31 11:13",
    status:0
  },
  {
    id:"160847",
    control:"查看代理商",
    address: '183.220.76.28',
    user_name: '广西程库科技有限公司 ',
    user_id: 1,
    time:"2023-1-31 11:20",
    status:0
  },
]

// const uploadRef=ref()
// const beforeUpload=(file)=>{
//   console.log(file);
// }

</script>

<template>
    <div class="container">
        <el-table :data="tableData" style="width: 100%" :size="large">
            <el-table-column prop="id" label="编号" />
            <el-table-column prop="control" label="操作类型" />
            <el-table-column prop="address" label="IP地址" />
            <el-table-column prop="user_name" label="用户名" />
            <el-table-column prop="user_id" label="用户ID"  />
            <el-table-column prop="time" label="操作时间" />
            <el-table-column prop="status" label="状态">
              <template #default="scope">
                <el-tag v-if="scope.row.status==0" type="success" effect="dark">成功</el-tag>
                <el-tag v-if="scope.row.status==1" type="warning" effect="dark">警告</el-tag>
                <el-tag v-if="scope.row.status==2" type="danger" effect="dark">失败</el-tag>
              </template>
            </el-table-column>
        </el-table>


        <!-- <el-upload ref="uploadRef" action="" :auto-upload="autoUpload" :before-upload="beforeUpload">
          <el-button type="primary" size="small" >上传</el-button>
        </el-upload> -->

   </div>
</template>